<template>
    <div class="cardWrapper">
      <div class="imgContainer">
        <img class="imgCert" :src="props.img" alt="" />
      </div>
      <div class="textUnderImage">
        <div class="itemListWrapper">
          <TitleGradientVue>
            <p class="titleGradient">
              {{ props.title }}
            </p>
          </TitleGradientVue>
          <ul class="itemList">
            <li class="listItemText">
              {{ props.day }}
            </li>
            <li class="listItemText">
              {{ props.price }}
            </li>
            <li class="listItemText">оригиналы доставляем бесплатно</li>
          </ul>
           <ButtonComp
            @click="
              isOpenOrderDialog ? store.closeDialog() : store.openDialog()
            "
            class="default default-btn"
          >
            Заказать</ButtonComp
          >
        </div>
      </div>
    </div>
</template>

<script setup>
import ButtonComp from "@/ui/ButtonComp.vue";
import TitleGradientVue from "@/ui/TitleGradient.vue";
import { defineProps } from "vue";
import { storeToRefs } from "pinia";
import { useOrderDialogStore } from "@/store/orderDialog";
const props = defineProps(["title", "day", "price", "img"]);

const store = useOrderDialogStore();
const { isOpenOrderDialog } = storeToRefs(store);
</script>

<style lang="scss" scoped>
.cardWrapper {
  position: relative;
  max-width: 305px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-btn {
  margin-top: 24px;
  width: 100%;
  font-weight: 500;
}

.imgContainer{
  height: 430px;
}

.itemList {
  margin-left: 20px;
}

.listItemText {
  list-style: disc;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-wrap: nowrap;
}

.imgCert {
  width: 100%;
  border-radius:16px ;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

}

.titleGradient {
  font-size: 16px;
  margin-bottom: 37px;
  text-wrap: nowrap;
}

.itemListWrapper {
  position: absolute;
  padding-top: 20px;
  padding-bottom: 24px;
  padding-right: 20px;
  padding-left: 20px;
  top: 200px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 227px;
  border-radius: 20px;
  width: 305px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
</style>
