<template>
  <div class="sticky">
    <div class="container">
      <div  id="header" class="connection">
        <a class="mail" href="mailto:olga.baloshkina@mail.ru">olga.baloshkina@mail.ru</a>
        <a class="mail" href="mailto:juli.shulga@bk.ru">juli.shulga@bk.ru</a>
        <LinksContact class="links"></LinksContact>
      </div>
    </div>
    <div class="border"></div>
    <div class="container">
      <div class="header">
        <div class="header-wrapper">
          <a href="#wrapper-ban">
            <div class="logo"  ><img src="../../public/logo.svg" alt="" /></div>
          </a>
          <ul class="nav">
            <a v-for="link in links" :key="link.id" :href="link.href" v-smooth-scroll class="nav-links">{{
              link.title
            }}</a>
          </ul>
        </div>
    
        <div class="header-wrapper ">
          <div class="burger">
            <img v-show="!burger" @click="burger = !burger" src="../../public/icons/burger.svg" alt="" class="burger-img">
            <img v-show="burger" @click="burger = !burger" src="../../public/icons/close.svg" alt="" class="burger-img">
          </div>
          <ul class="phones">
            <LinkPhone href="tel:+78632066575" class="phone">+7 (863) 206 65 75</LinkPhone>
            <LinkPhone href="tel:+79526011907" class="phone">+7 (952) 601 19 07</LinkPhone>
          </ul>
          <ButtonComp @click="isOpenOrderDialog ? store.closeDialog() : store.openDialog()" class="default button">
            Заказать звонок</ButtonComp>
          <DialogComp :visible="visible" :step="step"></DialogComp>
        </div>
        <HeaderBurger @click-link="burger=false" :class="{ active: burger }" class="header-burger"></HeaderBurger>
      </div>
    </div>
  </div>
</template>
<script setup>
import LinksContact from "@/ui/LinksContact.vue";
import LinkPhone from "@/ui/LinkPhone.vue";
import ButtonComp from "@/ui/ButtonComp.vue";
import HeaderBurger from "@/Layouts/HeaderBurger.vue"
import DialogComp from "@/ui/DialogComp.vue"

import { ref, watch } from "vue";
import { useOrderDialogStore } from "@/store/orderDialog"
import { storeToRefs } from 'pinia'

const store = useOrderDialogStore();
const { isOpenOrderDialog } = storeToRefs(store);
const burger = ref(false);

watch(burger, (value) => {
  document.body.style.overflow = value ? 'hidden' : 'auto';
})


const links = [
  {
    id: 0,
    title: "Маркетплейсы",
    href: "#mp"
  },
  {
    id: 1,
    title: "Сертификаты",
    href: "#serts"
  },
  {
    id: 2,
    title: "О нас",
    href: "#about"
  },
  {
    id: 3,
    title: "Контакты",
    href: "#contact"
  },
];
</script>
<style lang="scss" scoped>

.connection {
  display: flex;
  justify-content: end;
  gap: 20px;

  @media (max-width: 1024px) {
    display: none;
  }
}

.mail {
  display: flex;
  align-items: center;
  color: rgba(120, 120, 120, 1);
}

.header-burger {
  margin-top: 10px;
  position: fixed;
  z-index: 50;
  top: 79px;
  left: -100%;
  width: 100%;
  height: 100vh;
  background: #fff;
  transition: left .3s ease-in-out;

  &.active {
    left: 0;
  }
}

.container {
  max-width: 1280px;
  margin: 0 auto;
}

.button {
  margin: auto;

  @media (max-width: 1024px) {
    display: none;
  }

  @media (max-width: 1440px) {
    margin-right: 10px;
  }
}

.links {
  display: flex;
  justify-content: end;

  @media (max-width: 1024px) {
    display: none;
  }
}

.header-wrapper {
  display: flex;
  

  @media (max-width: 1024px) {
    flex-direction: row-reverse;
  }
}

.border {
  position: relative;
  height: 1px;
  background: #dde1e6;

  @media (max-width: 1024px) {
    display: none;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding-top: 9px;
  position: relative;
}

.logo {
  max-width: 56px;
  max-height: 70px;
  margin-bottom: 10px;

  @media (max-width: 1440px) {
    padding-left: 10px;
  }
}

.burger {
  display: flex;
  justify-content: flex-end;
  max-width: 33px;
  max-height: 70px;

  @media (min-width: 1024px) {
    display: none;
    width: 33px;
    height: 70px;
  }

  @media (max-width: 1440px) {
    padding-right: 10px;
  }
}

.burger-img {
  cursor: pointer;
}

.nav {
  margin-left: 32px;
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    display: none;
  }
}

.nav-links {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #21272A;
  transition: .3s ease-in-out;
  &:hover {
    color: #004bbb;
  }

  &:nth-child(n + 2) {
    margin-left: 24px;
  }
}

.phones {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-right: 20px;

  @media (max-width: 1024px) {
    margin-right: 80px;
  }
}

.phone {
  &:nth-child(n + 2) {
    margin-top: 9px;
  }
}
</style>
