import { createApp } from "vue";
import { createPinia } from "pinia";
import router from './router'
import PrimeVue from "primevue/config";
import App from "./App.vue";
import VueLazyLoad from 'vue3-lazyload'
import { createYmaps } from "vue-yandex-maps";
import "primevue/resources/themes/aura-light-green/theme.css";
import "primeicons/primeicons.css";
import VueSmoothScroll from 'vue3-smooth-scroll'

const pinia = createPinia();
const app = createApp(App);

app
  .use(
    createYmaps({
      apikey: "faf1ae4e-624a-4acd-99a7-77fdc0721070",
    })
  )
  .use(PrimeVue)
  .use(pinia)
  .use(router)
  .use(VueLazyLoad)
  .use(VueSmoothScroll)
  .mount("#app");
