<template>
  <div class="links">
    <ul class="media">
      <a v-for="item in media" :key="item.id" class="media-links" :href=item.link
        ><img :src="item.icon" class="media-links-icon"
      /></a>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.links {
  padding: 8px 0 6px 0;
}

.mail {
  display: flex;
  align-items: center;
  color: #787878;
  font-size: 14px;
  font-weight: 400;
  line-height: 15.4px;
  text-align: left;
}

.media {
  display: flex;
}

.media-links {
  display: block;
  width: 20px;
  height: 20px;
  &:nth-child(n + 2) {
    margin-left: 8px;
  }
}

.media-links-icon {
  width: 100%;
}
</style>

<script setup>
const media = [
  {
    id: 0,
    icon: "./icons/tg.svg",
    link: 'https://t.me/certificatrfrf'
  },
  {
    id: 1,
    icon: "./icons/inst.svg",
    link: ' https://instagram.com/sertcom_official'
  },
  {
    id: 2,
    icon: "./icons/vk.svg",
    link: 'https://vk.com/sertcom_official'
  },
  {
    id: 3,
    icon: "./icons/mail.svg",
    link: 'mailto:olga.baloshkina@mail.ru'
  },
];
</script>