<template>
  <div class="up-wrapper">
    <button @click="up" class="up-btn">
      <a class="up-link"
        ><img src="../../public/icons/up.svg" alt="" class="up-img"
      /></a>
    </button>
  </div>
  <TitleMain id="contact" class="title">Наши контакты</TitleMain>
  <div class="contacts">
    <div class="info">
      <LinkPhone href="tel:+78632066575" class="phone"
        >+7 (863) 206 65 75</LinkPhone
      >
      <LinkPhone href="tel:+79526011907" class="phone"
        >+7 (952) 601 19 07</LinkPhone
      >
      <div class="links">
        <a class="mail" href="mailto:olga.baloshkina@mail.ru"
          >olga.baloshkina@mail.ru</a
        >
        <a class="mail" href="mailto:juli.shulga@bk.ru">juli.shulga@bk.ru</a>
        <ul class="media">
          <a
            v-for="item in media"
            :key="item.id"
            class="media-links"
            :href="item.link"
            ><img class="media-links-icon" :src="item.icon"
          /></a>
        </ul>
      </div>
      <div class="addresses">
        <li class="item">
          <div class="marker-contact">
            <img
              src="../../public/icons/marker.svg"
              alt=""
              class="marker-img"
            />
          </div>
          <div class="location">
            <a
              href="https://yandex.ru/maps/org/sertkom/56719325036/?ll=39.699759%2C47.216307&z=17.01"
              target="_blank"
              class="adress"
            >
              г.Ростов-на-Дону, ул. Темерницкая 5а, офис 53
            </a>
            <div class="time">С 9.00 до 18.00</div>
          </div>
        </li>

        <li class="item">
          <div class="marker-contact">
            <img
              src="../../public/icons/marker.svg"
              alt=""
              class="marker-img"
            />
          </div>
          <div class="location">
            <a
              href="https://yandex.ru/maps/193/voronezh/house/pereulok_zdorovya_90_1/Z0AYdAdnS0cFQFtrfXtxcnxiYA==/?ll=39.207222%2C51.703014&mode=search&sctx=ZAAAAAgBEAAaKAoSCfYKC%2B4HlkNAEbcqieyDyklAEhIJmzv6X67F7z8ROBQ%2BWwcH1j8iBgABAgMEBSgKOABAsFNIAWoCcnWdAc3MTD2gAQCoAQC9AZLPtHKCAg7QodC10YDRgtCa0L7QvIoCAJICAJoCDGRlc2t0b3AtbWFwcw%3D%3D&sll=39.207222%2C51.703014&sspn=0.014779%2C0.005109&text=%D0%A1%D0%B5%D1%80%D1%82%D0%9A%D0%BE%D0%BC&z=17.06"
              target="_blank"
              class="adress"
            >
              г. Воронеж, ул. Переулок здоровья д. 90/1
            </a>
            <div class="time">С 9.00 до 18.00</div>
          </div>
        </li>
        <li class="item">
          <div class="marker-contact">
            <img
              src="../../public/icons/marker.svg"
              alt=""
              class="marker-img"
            />
          </div>
          <div class="location">
            <a
              href="https://yandex.ru/maps/geo/yekaterinburg/53166537/?ll=60.475066%2C56.788751&mode=search&sctx=ZAAAAAgBEAAaKAoSCfYKC%2B4HlkNAEbcqieyDyklAEhIJmzv6X67F7z8ROBQ%2BWwcH1j8iBgABAgMEBSgKOABAr80GSAFqAnJ1nQHNzEw9oAEAqAEAvQGSz7RyggIO0KHQtdGA0YLQmtC%2B0LyKAgCSAgCaAgxkZXNrdG9wLW1hcHM%3D&sll=60.475066%2C56.788751&sspn=1.274290%2C0.389138&text=%D0%A1%D0%B5%D1%80%D1%82%D0%9A%D0%BE%D0%BC&z=10.63"
              target="_blank"
              class="adress"
            >
              г. Екатеринбург
          </a>
            <div class="time">С 9.00 до 18.00</div>
          </div>
        </li>
      </div>
    </div>
    <div class="map-wrapper">
      <YandexMap class="map"></YandexMap>
    </div>
  </div>
</template>
<script setup>
import TitleMain from "@/ui/TitleMain.vue";
import LinkPhone from "@/ui/LinkPhone.vue";
import YandexMap from "@/ui/YandexMap.vue";

function up() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}

const media = [
  {
    id: 0,
    icon: "./icons/tg.svg",
    link: "https://t.me/certificatrfrf",
  },
  {
    id: 1,
    icon: "./icons/inst.svg",
    link: " https://instagram.com/sertcom_official",
  },
  {
    id: 2,
    icon: "./icons/vk.svg",
    link: "https://vk.com/sertcom_official",
  },
  {
    id: 3,
    icon: "./icons/mail.svg",
    link: "mailto:olga.baloshkina@mail.ru",
  },
];
</script>
<style lang="scss" scoped>
.up-wrapper {
  display: flex;
  justify-content: end;
}

.up-link {
  transition: 0.3s;
}

.up-btn {
  max-width: 40px;
  margin-top: 140px;

  @media (max-width: 1024px) {
    margin-top: 40px;
  }
}

.up-img {
  width: 100%;
}

.contacts {
  display: flex;
  margin-top: 40px;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.phone {
  &:nth-child(n + 2) {
    margin-top: 20px;
  }
}

.map {
  @media (max-width: 1024px) {
    margin-left: 0;
  }
}

.map-wrapper {
  width: 60%;

  @media (max-width: 1024px) {
    width: 100%;
  }
}

.links {
  margin-top: 40px;
}

.mail {
  display: flex;
  align-items: center;
  color: #787878;
  font-size: 14px;
  font-weight: 400;
  line-height: 15.4px;
  text-align: left;
  &:nth-child(n + 2) {
    margin-top: 20px;
  }
}

.media {
  display: flex;
  margin-top: 40px;
}

.media-links {
  display: block;
  width: 20px;
  height: 20px;
  &:nth-child(n + 2) {
    margin-left: 8px;
  }
}

.media-links-icon {
  width: 100%;
}

.addresses {
  margin-top: 40px;
  margin-bottom: 24px;
}

.item {
  display: flex;
  &:nth-child(n + 2) {
    margin-top: 20px;
  }
}

.marker-contact {
  width: 16px;
  height: 24px;
}

.marker-img {
  width: 100%;
}

.location {
  margin-left: 8px;
}

.adress {
  font-size: 15px;
  font-weight: 400;
  line-height: 20.4px;
  text-align: left;
  color: #000;
}

.time {
  font-size: 15px;
  font-weight: 400;
  line-height: 17.58px;
  text-align: left;
  color: rgba(33, 39, 42, 0.5);
}
</style>

