<template>
  <h3 class="gradient">
    <slot></slot>
  </h3>
</template>
<style lang="scss" scoped>
.gradient {
  background-color: #0197f4;
  background-image: linear-gradient(to right, #0197f4, #004bbb, #003c92);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  font-size: 24px;
  font-weight: 500;
  line-height: 31.2px;
  text-align: left;

  @media (max-width: 1024px) {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
  }
}
</style>