<template>
  <nav></nav>
  <router-view />
</template>
<style lang="scss">
#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(33, 39, 42, 1);
}

body {
  --scrollbar-width: 0;
}

.swiper-wrapper {
  height: 380px;
  padding-top: 50px;
}
.swiper-button-prev,
.swiper-button-next {
  display: fixed;
  top: 33px;
}
.swiper-button-prev {
  right: 80px;
  left: auto;
}

.swiper-button-next {
  right: 20px;
  left: auto;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 26px;
  font-weight: large;
  padding: 10px 16px;
  border: 1px solid rgba(33, 39, 42, 0.05);
  border-radius: 50%;
  width: 48px;
  height: 48px;
}
/* Reset and base styles  */
* {
  padding: 0px;
  margin: 0px;
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Links */

a,
a:link,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* Common */

aside,
nav,
footer,
header,
section,
main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: inherit;
  font-weight: inherit;
}

ul,
ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

img,
svg {
  // max-width: 100%;
  // height: auto;
}

address {
  font-style: normal;
}

/* Form */

input,
textarea,
button,
select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button,
input[type="submit"] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

input:focus,
input:active,
button:focus,
button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}
</style>
