<template>
  <yandex-map
    v-model="map"
    :settings="{
      location: {
        center: [39.699759, 47.216307],
        zoom: 14,
      },
    }"
    width="100%"
    height="527px"
  >
    <yandex-map-default-scheme-layer />
    <yandex-map-default-features-layer />
    <yandex-map-marker
      v-for="(marker, index) in markers"
      :key="index"
      :settings="marker"
    >
      <div class="marker" />
    </yandex-map-marker>
  </yandex-map>
</template>

<script setup>
import { shallowRef } from "vue";
import {
  YandexMap,
  YandexMapDefaultSchemeLayer,
  YandexMapDefaultFeaturesLayer,
  YandexMapMarker,
} from "vue-yandex-maps";

const map = shallowRef(null);
const markers = [
  {
    coordinates: [39.699759, 47.216307],
  },
  {
    coordinates: [39.207222, 51.703014],
  },
  {
    coordinates: [60.597474, 56.838011],
  },
];
</script>
<style scoped>
  .marker {
    position: relative;
    width: 1px;
    height: 1px;
    
  }

  .marker::before {
    position: absolute;
    content: "";
    top: -42px;
    left: -9px;
    width: 29px;
    height: 42px;
    background-image: url('../../public/icons/marker.svg');
  }
</style>