<template>
  <h1 class="title">
    <slot></slot>
  </h1>
</template>
<style lang="scss" scoped>
.title {
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
  text-align: left;

  @media (max-width: 1024px) {
    font-size: 26px;
    font-weight: 500;
    line-height: 31.2px;
    text-align: left;
  }
}
</style>