<template>
  <h2 class="subtitle">
    <slot></slot>
  </h2>
</template>
<style lang="scss" scoped>
.subtitle {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;

  @media (max-width: 1024px) {
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
    text-align: left;
  }
}
</style>