<template>
  <DialogComp :visible="visible"></DialogComp>
  <TitleMain class="title"
    >Подготовим документы за Вас в течение 1 дня.</TitleMain
  >
  <SubtitleMain class="subtitle">
    Одно из преимуществ нашей компании — это минимальное вовлечение клиента в процесс оформления, сделаем всю работу за Вас!
  </SubtitleMain>
  <ul  class="cards1">
    <CardTemplate v-for="card in cards" :key="card.number" class="card1">
      <div class="icon-number">
        <img :src="card.number" alt="" class="number" />
      </div>
      <TitleGradient class="card-title">{{ card.title }}</TitleGradient>
      <h4 class="card-subtitle">
        {{ card.subtitle }}
      </h4>
      <div class="card-img">
        <img v-lazy="card.img" alt="" class="img" />
      </div>
    </CardTemplate>
  </ul>
  <ButtonComp @click="isOpenOrderDialog ? store.closeDialog() : store.openDialog()" class="default btn">Заказать документы</ButtonComp>
</template>
<script setup>
import TitleMain from "@/ui/TitleMain.vue";
import SubtitleMain from "@/ui/SubtitleMain.vue";
import ButtonComp from "@/ui/ButtonComp.vue";
import CardTemplate from "@/ui/CardTemplate.vue";
import TitleGradient from "@/ui/TitleGradient";
import { useOrderDialogStore } from "@/store/orderDialog"
import { storeToRefs } from 'pinia' 
import DialogComp from "@/ui/DialogComp.vue"

const store = useOrderDialogStore();
const {isOpenOrderDialog} = storeToRefs(store)

const cards = [{
  number: './icons/01.svg',
  title: 'Оставьте заявку',
  subtitle: 'Находим наиболее короткий, оптимальный путь решения.',
  img: './img/fast0.png',
},
{
  number: './icons/02.svg',
  title: 'Обсуждение сертификата',
  subtitle: 'Уточняем, какой документ и для каких целей Вам необходим.',
  img: './img/fast1.png',
},
{
  number: './icons/03.svg',
  title: 'Подготавливаем документы',
  subtitle: 'Оформляем соответствующие документы, подготавливаем к передаче.',
  img: './img/fast2.png',
},
{
  number: './icons/04.svg',
  title: 'Выдача  документов',
  subtitle: 'Передаем готовые оригиналы документов.',
  img: './img/fast3.png',
}]
</script>
<style lang="scss" scoped>
.title {
  margin-top: 140px;

  @media (max-width: 1024px) {
    margin-top: 40px;
  }
}

.subtitle {
  margin-top: 40px;
}

.cards1 {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    gap: 16px;
    overflow-x: auto;
  }
}

.card1 {
  position: relative;
  min-width: 252px;
  width: 252px;
}

.icon-number {
  position: absolute;
  &::before {
    content: "";
    z-index: 10;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0) 80%
    );
  }
}

.card-title {
  padding-top: 55px;
  font-size: 24px;
  font-weight: 500;
  line-height: 26.4px;
  text-align: left;
  display: table-caption;
  position: relative;
  z-index: 10;
}

.card-subtitle {
  margin-top: 12px;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  height: 80px;
}

.card-img {
  max-width: 244px;
  margin-top: 20px;
}

.img {
  width: 100%;
}

.btn {
  margin-top: 40px;

  @media (max-width: 1024px) {
    width: 100%;
  }
}
</style>
