<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.card {
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);

  @media (max-width: 1024px) {
    padding: 10px;
  }
}
</style>