export const firstFourSerts = [{
  id: 0,
  img: './serts/sert1.png',
  title: 'Сертификат соответствия ТР ТС',
  day: 'от 10 дней',
  price: 'от 25 000Р',
},
{
  id: 1,
  img: './serts/sert0.png',
  title: 'Декларация соответствия',
  day: 'от 1 дня',
  price: 'от 4 500Р',
},
{
  id: 2,
  img: './serts/sert7.png',
  title: 'Свидетельство о государственной регистрации',
  day: 'от 14 дней',
  price: 'от 25 000Р',
},
{
  id: 8,
  img: './serts/sert8.png',
  title: 'Добровольный сертификат',
  day: 'от 2 дней',
  price: 'от 7 000Р',
}]

export const serts = [
  {
    id: 0,
    img: './serts/sert1.png',
    title: 'Сертификат соответствия ТР ТС',
    day: 'от 10 дней',
    price: 'от 25 000Р',
  },
  {
    id: 1,
    img: './serts/sert0.png',
    title: 'Декларация соответствия',
    day: 'от 1 дня',
    price: 'от 4 500Р',
  },
  {
    id: 2,
    img: './serts/sert7.png',
    title: 'Свидетельство о государственной регистрации',
    day: 'от 14 дней',
    price: 'от 25 000Р',
  },
  {
    id: 8,
    img: './serts/sert8.png',
    title: 'Добровольный сертификат',
    day: 'от 2 дней',
    price: 'от 7 000Р',
  },
{
  id: 4,
  img: './serts/sert4.png',
  title: 'Пожарный сертификат',
  day: 'от 3 дней',
  price: 'от 32 000Р',
},
{
  id: 5,
  img: './serts/sert5.png',
  title: 'Разъяснительное письмо',
  day: 'от 1 дня',
  price: 'от 2 000Р',
},
{
  id: 6,
  img: './serts/sert6.png',
  title: 'Сертификат происхождения',
  day: 'от 3 дней',
  price: 'от 25 500Р',
},
{
  id: 7,
  img: './serts/sert2.png',
  title: 'Экспертное заключение',
  day: 'от 10 дней',
  price: 'от 25 000Р',
},
{
  id: 8,
  img: './serts/sert3.png',
  title: 'ISO',
  day: 'от 1 дня',
  price: 'от 3 500Р',
},
{
  id: 9,
  img: './serts/sert9.png',
  title: 'Протокол испытаний',
  day: 'от 3 дней',
  price: 'от 6 000Р',
},
{
  id: 10,
  img: './serts/sert10.png',
  title: 'Сертификат соответствия ГОСТ',
  day: 'от 10 дней',
  price: 'от 20 000Р',
},
{
  id: 11,
  img: './serts/sert11.png',
  title: 'Технические условия',
  day: 'от 3 дней',
  price: 'от 6 000Р',
},
{
  id: 12,
  img: './serts/sert12.png',
  title: 'Стандарт организации',
  day: 'от 3 дней',
  price: 'от 6 000Р',
},
{
  id: 13,
  img: './serts/sert13.png',
  title: 'Экологический сертификат соответствия',
  day: 'от 4 дней',
  price: 'от 11 000Р',
},
{
  id: 14,
  img: './serts/sert14.png',
  title: 'Руководство по эксплуатации',
  day: 'от 3 дней',
  price: 'от 6 000Р',
},
{
  id: 15,
  img: './serts/sert15.png',
  title: 'Паспорт качества',
  day: 'от 3 дней',
  price: 'от 6 000Р',
},
{
  id: 16,
  img: './serts/sert16.png',
  title: 'Отказное письмо',
  day: 'от 1 дня',
  price: 'от 2 000Р',
},
{
  id: 17,
  img: './serts/sert17.png',
  title: 'Отрицательное решение по заявкам',
  day: 'от 1 дня',
  price: 'от 2 000Р'
},
{
  id: 18,
  img: './serts/sert18.png',
  title: 'Протокол испытаний на сроки годности',
  day: 'от 5 дней',
  price: 'от 15 000Р',
}] 