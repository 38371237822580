import { defineStore } from 'pinia'
import { ref } from "vue";

export const useOrderDialogStore = defineStore('orderDialog', () => {
    const isOpenOrderDialog = ref(false);
    const isStep = ref(4);
    const header = 'Заказать документы'
    const openDialog = () => isOpenOrderDialog.value = true;
    const closeDialog = () => isOpenOrderDialog.value = false;
    return {isOpenOrderDialog, openDialog, closeDialog, isStep, header}
  })