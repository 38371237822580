<template>
  <div>
    <div class="border"></div>
    <ul class="nav">
      <a
        v-for="link in links"
        @click="$emit('clickLink')"
        :key="link.id"
        :href="link.href"
        v-smooth-scroll
        class="nav-links"
        >{{ link.title }}</a
      >
    </ul>
    <div class="border"></div>
    <ul class="phones">
      <LinkPhone href="tel:+78632066575" class="phone"
        >+7 (863) 206 65 75</LinkPhone
      >
      <LinkPhone href="tel:+79526011907" class="phone"
        >+7 (952) 601 19 07</LinkPhone
      >
    </ul>
    <div class="mails">
      <a class="mail" href="mailto:olga.baloshkina@mail.ru"
        >olga.baloshkina@mail.ru</a
      >
      <a class="mail" href="mailto:juli.shulga@bk.ru">juli.shulga@bk.ru</a>
    </div>
    <ul class="media">
      <a
        v-for="item in media"
        :key="item.id"
        class="media-links"
        :href="item.link"
        ><img :src="item.icon" class="media-links-icon"
      /></a>
    </ul>
  </div>
</template>
<style lang="scss" scoped>
.nav {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  margin-bottom: 65px;
  padding: 0 16px;
}

.nav-links {
  font-size: 26px;
  font-weight: 500;
  line-height: 31.2px;
  text-align: left;
  color: #21272a;

  &:hover {
    color: #004bbb;
    transition: 0.3s;
  }

  &:nth-child(n + 2) {
    margin-top: 32px;
  }
}

.phones {
  margin-top: 32px;
  padding: 0 16px;
}

.phone {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;

  &:nth-child(n + 2) {
    margin-top: 8px;
  }
}

.mails {
  display: flex;
  flex-direction: column;
  margin-top: 44px;
  padding: 0 16px;
}

.mail {
  color: black;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;

  &:nth-child(n + 2) {
    margin-top: 8px;
  }
}

.links {
  margin-top: 44px;
  margin-bottom: 184px;
}

.media {
  display: flex;
  margin-top: 44px;
  margin-bottom: 184px;
  padding: 0 16px;
}

.media-links {
  display: block;
  width: 40px;
  height: 40px;
  &:nth-child(n + 2) {
    margin-left: 8px;
  }
}

.media-links-icon {
  width: 100%;
}

.border {
  height: 1px;
  background: #dde1e6;
}
</style>
<script setup>
import LinkPhone from "@/ui/LinkPhone.vue";

const links = [
  {
    id: 0,
    title: "Маркетплейсы",
    href: "#mp",
  },
  {
    id: 1,
    title: "Сертификаты",
    href: "#serts",
  },
  {
    id: 2,
    title: "О нас",
    href: "#about",
  },
  {
    id: 3,
    title: "Контакты",
    href: "#contact",
  },
];

const media = [
  {
    id: 0,
    icon: "./icons/tg.svg",
    link: "https://t.me/certificatrfrf",
  },
  {
    id: 1,
    icon: "./icons/inst.svg",
    link: " https://instagram.com/sertcom_official",
  },
  {
    id: 2,
    icon: "./icons/vk.svg",
    link: "https://vk.com/sertcom_official",
  },
  {
    id: 3,
    icon: "./icons/mail.svg",
    link: "mailto:olga.baloshkina@mail.ru",
  },
];
</script>
