<template>
  <section>
    <TitleMain id="serts" class="title"
      >Оформление более 50 типов документаций</TitleMain
    >
    <div class="cardWrapperMain">
      <div v-for="sert in dataArray" :key="sert.id" class="cardWrapper">
        <CardCertificat
          :img="sert.img"
          :title="sert.title"
          :day="sert.day"
          :price="sert.price"
        />
      </div>
    </div>
    <div class="buttonShow">
      <ButtonComp @click="buttonShow" class="show show-btn">{{
        buttonText()
      }}</ButtonComp>
    </div>
  </section>
</template>

<script setup>
import CardCertificat from "@/components/SertProcces/CardCertificat.vue";
import ButtonComp from "@/ui/ButtonComp.vue";
import TitleMain from "@/ui/TitleMain.vue";
import { serts } from "@/api/sertsService";
import { ref } from "vue";

const generateLimit = () => {
  const width = window.innerWidth;

  if (width > 1280) {
    return 4;
  } else if (width <= 1280 && width > 1024) {
    return 3;
  } else if (width <= 1024 && width > 700) { 
    return 2;
  } else if (width <= 700) {
    return 1;
  }
};
const limitEnd = ref(generateLimit());
const dataArray = ref(serts.slice(0, limitEnd.value));

const buttonShow = () => {
  if (dataArray.value.length !== serts.length) {
    dataArray.value = serts;
  } else {
    dataArray.value = serts.slice(0, limitEnd.value);
  }
};

const buttonText = () => {
  if (dataArray.value.length !== serts.length) {
    return "Показать еще";
  } else {
    return "Скрыть";
  }
};
</script>

<style lang="scss" scoped>
.title {
  margin-top: 140px;
  margin-bottom: 24px;
  @media (max-width: 1024px) {
    margin-top: 40px;
  }
}

.cardWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cardWrapperMain {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 40px;
  column-gap: 20px;
  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
    margin-left: 20px;
    margin-right: 20px;
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    
  }
   @media screen and (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
    
  }
}

.buttonShow {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
