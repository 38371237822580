<template>
  <teleport to="body">
    <Dialog :visible="props.visible" modal :header="store.isStep == 5 ? '' : store.header"
      :style="{ width: '500px' }" 
      :pt="{
      root: { class: 'root-modal' },
      }"
      @update:visible="!$event && close()">
      <div class="modal-container">
        <div v-show="store.isStep == 1" class="">
          <div class="modal">
            <div class="question-ban">Вопрос 1 из 3</div>
            <div class="modal-title-ban">
              Какой вид документации Вас интересует?
            </div>
            <textarea required pattern=".{1,}" v-model="type" class="modal-input" name="story" rows="5"
              cols="33">например: декларация соответствия</textarea>
            <div class="modal-btns">
              <ButtonComp @click="nextStep" :disabled="!isFirstStepValid" class="default modal-btn1">Далее</ButtonComp>
            </div>
          </div>
        </div>
        <div v-show="store.isStep == 2" class="">
          <div class="modal">
            <div class="question-ban">Вопрос 2 из 3</div>
            <div class="modal-title-ban">
              На какую продукцию/услугу вам необходимо получить документацию?
            </div>
            <textarea v-model="products" class="modal-input" name="story" rows="5"
              cols="33">например: генератор азота</textarea>
            <div class="modal-btns">
              <ButtonComp @click="nextStep" :disabled="!isSecondStepValid" class="default modal-btn1">Далее</ButtonComp>
              <button @click="nextStep" class="modal-btn2">Пропустить</button>
            </div>
          </div>
        </div>
        <div v-show="store.isStep == 3" class="">
          <div class="modal">
            <div class="question-ban">Вопрос 3 из 3</div>
            <div class="modal-title-ban">
              От какого лица реализуете или планируете реализовывать товар?
            </div>
            <textarea v-model="organization" class="modal-input" name="story" rows="5" cols="33">например: ИП</textarea>
            <div class="modal-btns">
              <ButtonComp @click="nextStep" :disabled="!isThirdStepValid" class="default modal-btn1">Далее</ButtonComp>
              <button @click="nextStep" class="modal-btn2">Пропустить</button>
            </div>
          </div>
        </div>
        <div v-show="store.isStep == 4" class="">
          <div class="modal">
            <div class="modal-title-ban">
              Мы свяжемся с Вами в ближайшее время
            </div>
            <div class="inputs-banner">
              <input v-model="name" class="input-banner" type="text" placeholder="Имя" />
              <InputMask minlength="12" v-model="phone" class="input-banner" type="text"
                placeholder="+7 (999) 999 99 99" id="phone" mask="+7 (999) 999-99-99" />
              <input v-model="comment" class="input-banner" type="text" placeholder="Комментарий" />
              <input v-model="antispam" style="width: 0;" type="text">
            </div>
            <p class="policy-banner">
              Нажимая кнопку "Отправить", Вы соглашаетесь c
              <span @click="rout()" class="policy-link-banner">политикой конфиденциальности</span>
            </p>
            <ButtonComp @click="submitForm" :disabled="!isFourStepValid"  class="default btn-banner">Отправить</ButtonComp>
          </div>
        </div>
        <div v-show="store.isStep == 5" class="">
          <div class="modal">
            <img class="modal-ok" src="../../public/icons/ok.svg" alt="" />
            <ButtonComp @click="close" class="show btn-banner">На главную</ButtonComp>
          </div>
        </div>
      </div>
    </Dialog>
  </teleport>
</template>
<script setup>
import ButtonComp from "@/ui/ButtonComp.vue";
import router from "@/router";
import InputMask from 'primevue/inputmask';
import axios from 'axios';
import Dialog from "primevue/dialog";
import { ref, defineProps, computed } from "vue";
import { useOrderDialogStore } from "@/store/orderDialog"

const rout = () => router.push("/privacy")
const antispam = ref('')
const type = ref('')
const products = ref('')
const organization = ref('')
const name = ref('')
const phone = ref('')
const comment = ref('')

const store = useOrderDialogStore();

const props = defineProps({
  visible: { type: Boolean, default: false }
})
// const step = ref(1);
const nextStep = () => store.isStep++;
const close = () => {
  store.header = 'Заказать документы';
  store.isStep = 4;
  store.closeDialog();
}

const isFirstStepValid = computed(() => {
  if (type.value && type.value !== '') return true;
  return false
});

const isSecondStepValid = computed(() => {
  if (products.value && products.value !== '') return true;
  return false
});

const isThirdStepValid = computed(() => {
  if (organization.value && organization.value !== '') return true;
  return false
});

const isFourStepValid = computed(() => {
  if (!name.value || name.value === '') return false;
  if (!phone.value || phone.value === '') return false;
  if (!antispam.value && antispam.value !== '') return false;
  return true
});

async function submitForm() {
  const formData = new FormData();
  formData.append('header', store.header)
  formData.append('type', type.value);
  formData.append('products', products.value);
  formData.append('organization', organization.value);
  formData.append('name', name.value);
  formData.append('phone', phone.value);
  formData.append('comment', comment.value);

  try {
    const response = await axios.post('/mail.php', formData, {
      method: 'POST',
      body: formData
    });
    console.log('Response:', response.data);
  } catch (error) {
    console.error('Error:', error,);
  }
  nextStep()
}
</script>
<style lang="scss">
.root-modal {
  padding: 32px;
  font-family: "Roboto", sans-serif;
}

.modal {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.modal-ok {
  margin-top: 20px;
  width: 100%;
}

.question-ban {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-top: 36px;
}

.modal-title-ban {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  margin-top: 16px;
}

.modal-input {
  width: 100%;
  border: 1px solid rgba(217, 217, 217, 1);
  margin-top: 16px;
  padding: 16px;
  resize: none;
}

.modal-btns {
  margin-top: 16px;
  display: flex;
}

.modal-btn2 {
  padding: 16px 28px;
}

.inputs-banner {
  display: flex;
  flex-direction: column;
}

.input-banner {
  border: 1px solid rgba(33, 39, 42, 0.5);
  border-radius: 5px;
  margin-top: 16px;
  height: 48px;
  padding-left: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.policy-banner {
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(33, 39, 42, 0.5);

  span {
    cursor: pointer;
    text-decoration: underline;
  }
}

.btn-banner {
  margin-top: 16px;
  width: 100%;
}
</style>