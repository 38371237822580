<template>
  <CardTemplate class="form">
    <teleport to="body">
      <Dialog :visible="visible" modal :closeButton="false" header=" ">
        <img class="ok" src="../../public/icons/ok.svg" alt="" />
      </Dialog>
    </teleport>
    <slot></slot>
    <div class="inputs">
      <input v-model="name" class="input" type="text" placeholder="Имя" />
      <InputMask minlength="12" v-model="phone" class="input-banner" type="text" placeholder="+7 (999) 999 99 99"
        id="phone" mask="+7 (999) 999-99-99" />
      <input v-model="comment" class="input" type="text" placeholder="Комментарий" />
    </div>
    <p class="policy">
      Нажимая кнопку «Получить консультацию», Вы соглашаетесь c
      <span @click="rout()" class="policy-link">политикой конфиденциальности</span>
      и получите консультацию эксперта по сертификации и индивидуальное коммерческое предложение.
    </p>
    <ButtonComp @click="submitForm" :disabled="!isFourStepValid" class="default btn">Получить консультацию</ButtonComp>
  </CardTemplate>
</template>
<script setup>
import Dialog from 'primevue/dialog';
import ButtonComp from "@/ui/ButtonComp.vue";
import CardTemplate from "@/ui/CardTemplate.vue";
import InputMask from 'primevue/inputmask';
import axios from 'axios';
import router from "@/router";
import { ref, computed } from "vue";
const visible = ref(false);
const rout = () => router.push("/privacy")
const name = ref('')
const phone = ref('')
const comment = ref('')
const isFourStepValid = computed(() => {
  if (!name.value || name.value === '') return false;
  if (!phone.value || phone.value === '') return false;
  return true
});


async function submitForm() {
  const formData = new FormData();
  formData.append('name', name.value);
  formData.append('phone', phone.value);
  formData.append('comment', comment.value);

  try {
    const response = await axios.post('/mail.php', formData, {
      method: 'POST',
      body: formData
    });
    console.log('Response:', response.data);
  } catch (error) {
    console.error('Error:', error);
  }

  visible.value = true;

  function close () {
    visible.value = false
  }
  setInterval(close, 2000);
  name.value = phone.value = comment.value = ''
}
</script>
<style lang="scss" scoped>
.ok {
  padding: 30px;
}
.form {
  padding: 32px;
  max-width: 503px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    padding: 0;
  }
}

.inputs {
  display: flex;
  flex-direction: column;
}

.input {
  border: 1px solid rgba(33, 39, 42, 0.5);
  border-radius: 5px;
  margin-top: 16px;
  height: 48px;
  padding-left: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.policy {
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgba(33, 39, 42, 0.5);

  span {
    cursor: pointer;
    text-decoration: underline;
  }
}

.btn {
  margin-top: 16px;
  width: 100%;
}
</style>