<template>
  <nav></nav>
  <router-view />
  <HeaderMain class="sticky"></HeaderMain>
  <BannerComp></BannerComp>
  <div class="wrapper">
    <SertMarketplace></SertMarketplace>
    <SertProcessing />
    <SertTopTen></SertTopTen>
    <SertShared></SertShared>
    <SertAdvantages></SertAdvantages>
    <SertFast></SertFast>
    <SertFocus></SertFocus>
    <SertRequest></SertRequest>
    <!-- <SertFeedback></SertFeedback> -->
    <SertFaq></SertFaq>
    <SertContacts></SertContacts>
    <DialogComp :visible="isOpenOrderDialog"></DialogComp>
  </div>
  <SertFooter></SertFooter>
</template>
<script setup>
import SertProcessing from "@/components/SertProccesing.vue";
import HeaderMain from "@/Layouts/HeaderMain.vue";
import BannerComp from "@/components/BannerComp.vue";
import SertMarketplace from "@/components/SertMarketplace.vue";
import SertTopTen from "@/components/SertTopTen.vue";
import SertShared from "@/components/SertShared.vue";
import SertAdvantages from "@/components/SertAdvantages.vue";
import SertFast from "@/components/SertFast.vue";
import SertFocus from "@/components/SertFocus.vue";
import SertRequest from "@/components/SertRequest.vue";
import SertFooter from "@/components/SertFooter.vue";
import SertContacts from "@/components/SertContacts.vue";
import SertFaq from "@/components/SertFaq.vue";
import { useOrderDialogStore } from "@/store/orderDialog";
import { storeToRefs } from "pinia";
import DialogComp from "@/ui/DialogComp.vue";
// import SertFeedback from "@/components/SertFeedback.vue"

const store = useOrderDialogStore();
const { isOpenOrderDialog } = storeToRefs(store);
</script>

<style lang="scss" scoped>
.sticky {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
}
.wrapper {
  max-width: 1280px;
  margin: 0 auto;

  @media (max-width: 1440px) {
    padding: 0 10px;
  }

  @media (max-width: 1024px) {
    padding: 0 10px;
  }
}
</style>
