<template>
  <TitleMain id="mp" class="mp-title">Сертификация товаров для маркетплейсов</TitleMain>
  <SubtitleMain class="mp-subtitle">Сегодня онлайн-торговые площадки становятся ключевым местом для успешной торговли
    товарами, и любая продукция, которая продается на маркетплейсах, должна быть сертифицирована. Для этого мы готовы
    оказать помощь в оформлении необходимых сертификатов и деклараций для самых крупных и популярных онлайн-платформ.
  </SubtitleMain>
  <div class="mp-cards">
    <CardTemplate class="mp-item" v-for="item in marketplaceCards" :key="item.id">
      <TitleGradient class="mp-card-title">{{ item.title }}</TitleGradient>
      <div class="wrapper-img">
        <img v-lazy="item.icon" alt="" class="mp-img" />
      </div>
    </CardTemplate>
  </div>
  <Accordion unstyled class="accordion" :activeIndex="1" :pt="{
    root: { class: 'root' },
  }">
    <template #collapseicon>
      <img class="icon" src="../../public/icons/minus.svg" alt="" />
    </template>
    <template #expandicon>
      <img class="icon" src="../../public/icons/plus.svg" alt="" />
    </template>
    <AccordionTab header="Перечень базовых документов для продажи на маркетплейсах" :pt="{
      headerTitle: { class: 'headerTitle' },
      headerIcon: { class: 'headerIcon' },
      headerAction: { class: 'headerAction' },
      root: { class: 'root-tab' },
    }">
      <template #default>
        <div class="default-container">
          <ul class="accordion-items">
            <li class="accordion-item">
              Удостоверение, подтверждающее регистрацию юридического лица на
              территории России;
            </li>
            <li class="accordion-item">
              Справка о самозанятости - (для самозанятых);
            </li>
            <li class="accordion-item">
              свидетельство о регистрации и свидетельство о получении ОГРНИП -
              для ИП;
            </li>
            <li class="accordion-item">Копия паспорта;</li>
            <li class="accordion-item">СНИЛС юридического лица или ИП;</li>
            <li class="accordion-item">
              Документы, подтверждающие права на товар (например, свидетельство
              товарного знака и лицензия);
            </li>
            <li class="accordion-item">реквизиты банковского счета;</li>
            <li class="accordion-item">
              Выписка из ЕГРЮЛ, договор на аренду склада и т. д.;
            </li>
            <li class="accordion-item">
              Доверенность на управление аккаунтом на торговой онлайн-площадке
              (при необходимости).
            </li>
          </ul>
          <h4 class="accordion-subtitle">
            Также необходимо предоставить официальные бумаги-подтверждение на
            товар. Чтобы законно продавать любой товар, потребуется один из
            четырех документов:
          </h4>
          <ul class="accordion-items">
            <li class="accordion-item">Сертификат соответствия;</li>
            <li class="accordion-item">Декларация соответствия;</li>
            <li class="accordion-item">
              СГР (Свидетельство о государственной регистрации);
            </li>
            <li class="accordion-item">
              Отказное письмо (Отрицательное решение).
            </li>
          </ul>
        </div>
      </template>
    </AccordionTab>
  </Accordion>
  <WarningComp>
    Согласно договорам оферты предпринимателя с маркетплейсом следует: с момента поступления запроса на требования о
    предоставлении всех необходимых документов на реализуемый товар, у предпринимателя есть всего лишь 3 рабочих дня. За
    отсутствие разрешительной документации карточка товара блокируется, а товар возвращается предпринимателю.
  </WarningComp>
</template>
<script setup>
import CardTemplate from "@/ui/CardTemplate.vue";
import TitleMain from "@/ui/TitleMain.vue";
import SubtitleMain from "@/ui/SubtitleMain.vue";
import WarningComp from "@/ui/WarningComp.vue";
import TitleGradient from "@/ui/TitleGradient";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import "primeicons/primeicons.css";

const marketplaceCards = [
  {
    id: 0,
    title: "Сертификация для Ozon",
    icon: "./img/ozon.png",
  },
  {
    id: 1,
    title: "Сертификация для Wildberries",
    icon: "./img/wb.png",
  },
  {
    id: 2,
    title: "Сертификация для Яндекс Маркет",
    icon: "./img/ya.png",
  },
  {
    id: 3,
    title: "Сертификация для AliExpress",
    icon: "./img/ali.png",
  },
];
</script>
<style lang="scss">
.mp-marker {
  border-radius: 50%;
  width: 4px;
  height: 4px;
  background: black;
}

.accordion {
  margin-top: 32px;
}

.root-tab {
  border-radius: 16px;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.05);
  margin-top: 4px;
}

.root-tab[data-p-active="false"] {
  border: 2px solid #ffffff;
}

.root-tab[data-p-active="true"] {
  border: 2px solid #004bbb;
}

.headerTitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 27.2px;
  text-align: left;
  display: flex;
  align-items: center;
}

.headerAction {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 25px 20px;
  cursor: pointer;
}

.default-container {
  padding: 0 20px 20px 20px;
}

.accordion-items {
  margin-left: 16px;

  li {
    list-style: outside;
  }
}

.accordion-item {
  &:nth-child(n + 2) {
    margin-top: 12px;
  }
}

.accordion-subtitle {
  padding: 20px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.76px;
  text-align: left;
}

.mp-title {
  margin-top: 80px;
  max-width: 655px;

  @media (max-width: 1024px) {
    margin-top: 40px;
  }
}

.mp-subtitle {
  margin-top: 24px;
  max-width: 936px;
}

.mp-card-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 31.2px;
  text-align: left;
}

.mp-cards {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
  }

  @media (max-width: 690px) {
    display: flex;
    flex-direction: column;
  }
}

.mp-item {
  max-width: 292px;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
}

.wrapper-img {
  margin-top: 20px;
  max-width: 244px;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
}

.mp-img {
  width: 100%;
  height: 185px;
  object-fit: cover;
  object-position: 50% 50%;

  @media (max-width: 1024px) {
    height: 165px;
  }
}
</style>