<template>
  <div class="wrapper-ban">
    <div class="bunner-ban">
    </div>
    <div class="layer-ban" id="wrapper-ban">
      <div class="content-ban">
        <h1 class="title-ban">
          Содействие в оперативной сертификации и декларировании товаров
        </h1>
        <h3 class="subtitle-ban">Узнайте за 1 минуту цену Вашего документа!</h3>
        <ButtonComp @click="openForm()" class="default btn-ban"
          >Рассчитать стоимость</ButtonComp
        >
      </div>
    </div>
    <DialogComp :visible="visible"></DialogComp>
    
  </div>
</template>
<script setup>
import ButtonComp from "@/ui/ButtonComp.vue";
import { useOrderDialogStore } from "@/store/orderDialog"
// import { storeToRefs } from 'pinia' 
import DialogComp from "@/ui/DialogComp.vue"

const store = useOrderDialogStore();
// const {isOpenOrderDialog} = storeToRefs(store)

function openForm() {
  store.isStep = 1;
  store.header = 'Узнайте за 1 минуту цену Вашего документа!';
  store.openDialog();
}

</script>
<style lang="scss" scoped>


.wrapper-ban {
  position: relative;
  
}
.layer-ban {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 119px;


  @media (max-width: 1440px) {
    background: rgba(202, 224, 255, 0.8);
  }
  @media (max-width: 1024px) {
    padding: 0 10px 25px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
  }
}

.content-ban {
  max-width: 1280px;
  margin: 0 auto;


  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.bunner-ban {
  height: 506px;
  background: #cae0ff;
  background-image: url("../../public/img/bunner.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  position: relative;
  padding: 119px 0 0 94px;

  @media (max-width: 1024px) {
    height: 324px;
    background-size: cover;
  }
}

.img-ban {
  position: absolute;
  top: 0;
  right: 0;
}

.title-ban {
  font-size: 48px;
  font-weight: 500;
  line-height: 48px;
  text-align: left;
  color: #015fca;
  max-width: 660px;

  @media (max-width: 1024px) {
    font-size: 26px;
    font-weight: 500;
    line-height: 31.2px;
    text-align: center;
  }
}

.subtitle-ban {
  margin: 20px 0 30px 0;
  font-size: 32px;
  font-weight: 400;
  line-height: 41.6px;
  text-align: left;
  color: #21272a;
  max-width: 510px;

  @media (max-width: 1024px) {
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    text-align: center;
  }
}

.btn-ban {
  @media (max-width: 640px) {
    width: 100%;
  }
}
</style>