<template>
  <TitleMain class="title"
    >Мы работаем, учитывая самые важные для Вас критерии в работе:</TitleMain
  >
  <ul class="cards">
    <li v-for="card in cards" :key="card.subtitle" class="card">
      <div class="card-img">
        <img :src="card.img" alt="" class="icon" />
      </div>
      <div class="subtitle">{{ card.subtitle }}</div>
    </li>
  </ul>
</template>
<script setup>
import TitleMain from "@/ui/TitleMain.vue";

const cards = [{
  img: './icons/fast1.png',
  subtitle: 'Оперативные сроки выпуска документов.'
},
{
  img: './icons/fast2.png',
  subtitle: 'Выгодные рыночные цены и индивидуальные ценовые решения для корпоративных клиентов.'
},
{
  img: './icons/fast3.png',
  subtitle: '100% - ая законность и правильность оформления документов с гарантией прохождения всех проверок надзорными органами.'
},
{
  img: './icons/fast4.png',
  subtitle: 'Полное сопровождение сделки от звонка в компанию до консультаций уже после получения оригиналов документов.'
},]
</script>
<style lang="scss" scoped>
.title {
  margin-top: 140px;

  @media (max-width: 1024px) {
    margin-top: 40px;
  }
}

.cards {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    justify-content: center;
    gap: 60px;
  }

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.card {
  width: 200px;

  @media (max-width: 640px) {
    width: 100%;
  }
}

.card-img {
  max-width: 96px;
  height: 121px;

  @media (max-width: 640px) {
    margin: 0 auto;
  }
}

.icon {
  width: 100%;
}

.subtitle {
  margin-top: 16px;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;

  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 500px) {
    text-align: center;
  }
}
</style>
