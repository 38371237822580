<template>
  <a href="#" class="phone"><slot></slot></a>
</template>
<style lang="scss" scoped>
.phone {
  display: block;
  color: #015fca;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  transition: color 0.3s ease;

  &:hover {
    color: #3694ff;
  }
}
</style>