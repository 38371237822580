<template>
  <div class="footer">
    <div class="aaa">
      <div class="wrapper">
        <div class="wrap">
          <a @click="rout()" href="" class="policy">Политика обработки персональных данных</a>
          <div class="umk">
            <p class="umk-text">© 2024 Все права защищены. Разработано:</p>
            <a href="https://umk-web.com/" target="_blank">
              <img src="../../public/icons/umk.svg" alt="" class="umk-img" />
            </a>
          </div>
        </div>
        <a href="#wrapper-ban">
          <div class="logo">
            <img src="../../public/logo.svg" alt="" class="img" />
          </div>
        </a>
      </div>
      <div class="subtext">
        Сайт использует файлы «cookie» с целью персонализации сервисов и повышения удобства пользования веб-сайтом.
        «Cookie» представляют собой небольшие файлы, содержащие информацию о предыдущих посещениях веб-сайта. Если вы не
        хотите использовать файлы «cookie», измените настройки браузера.
      </div>
    </div>
  </div>
</template>

<script setup>
import router from "@/router";
const rout = () => router.push("/privacy")
</script>

<style lang="scss" scoped>
.footer {
  margin-top: 188px;
  background: rgba(0, 0, 0, 0.05);
  box-shadow: 0px -2px 9.699999809265137px 0px rgba(0, 0, 0, 0.05);

  @media (max-width: 1024px) {
    margin-top: 40px;
  }
}

.aaa {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 10px;
}

.wrapper {
  padding-top: 48px;
  display: flex;
  justify-content: space-between;
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.policy {

  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  text-decoration: underline;
  color: rgba(33, 39, 42);
}

.umk {
  display: flex;
}

.umk-text {
  font-size: 15px;
  font-weight: 400;
  line-height: 19.95px;
  text-align: left;
}

.umk-img {
  margin-left: 7px;
}

.logo {
  max-width: 50px;

  @media (max-width: 1024px) {
    display: none;
  }
}

.img {
  width: 100%;
  height: 100%
}

.text {
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  margin-top: 48px;
}

.subtext {
  color: rgba(33, 39, 42, 0.5);
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  margin-top: 48px;
}
</style>