<template>
  <TitleMain class="title"
    >ТОП-10 товарных категорий, нетребующих сертификации на
    маркетплейсах</TitleMain
  >

  <swiper
    :modules="modules"
    :slides-per-view="1"
    :space-between="50"
    navigation
    :breakpoints="{
      // when window width is >= 320px
      400: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      // when window width is >= 480px
      560: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      // when window width is >= 640px
      1024: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    }"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide>
      <div class="img-container">
        <img src="../../public/topten/topten1.svg" alt="" class="img" />
      </div>
      <h3 class="subtitle">Аксессуары для гаджетов</h3>
      <ul class="description">
        <p class="desc">которые можно продавать с отказным письмом:</p>
        <li class="desc">Наушники;</li>
        <li class="desc">Защитные стекла и пленки;</li>
        <li class="desc">Держатели;</li>
        <li class="desc">Чехлы;</li>
        <li class="desc">Подвески и наклейки.</li>
      </ul>
      <p class="annotation">
        * зарядные устройства к аксессуарам не относятся, на них нужна сертификация.
      </p>
    </swiper-slide>

    <swiper-slide>
      <div class="img-container">
        <img src="../../public/topten/topten2.svg" alt="" class="img" />
      </div>
      <h3 class="subtitle">Бижутерия и фурнитура</h3>
      <ul class="description">
        <p class="desc">которые можно продавать с отказным письмом:</p>
        <li class="desc">Серьги;</li>
        <li class="desc">Браслеты;</li>
        <li class="desc">Брелки;</li>
        <li class="desc">Запонки и др. продукция.</li>
      </ul>
    </swiper-slide>
    <swiper-slide>
      <div class="img-container">
        <img src="../../public/topten/topten3.svg" alt="" class="img" />
      </div>
      <h3 class="subtitle">Массажеры</h3>
      <ul class="description">
        <p class="desc">которые можно продавать с отказным письмом:</p>
        <li class="desc">Ручные роликовые модели;</li>
        <li class="desc">Массажные перчатки;</li>
        <li class="desc">Нефритовые ролики;</li>
        <li class="desc">Изделия-молекулы для всего тела;</li>
        <li class="desc">Чесалки для спины.</li>
      </ul>
      <p class="annotation">
        * Массажеры, работающие от батареек или аккумулятора, нужно
        сертифицировать/декларировать.
      </p>
    </swiper-slide>
    <swiper-slide>
      <div class="img-container">
        <img src="../../public/topten/topten4.svg" alt="" class="img" />
      </div>
      <h3 class="subtitle">Пряжа</h3>
      <ul class="description">
        <p class="desc">которые можно продавать с отказным письмом:</p>
        <li class="desc">Шерстяная;</li>
        <li class="desc">Полушерстяная;</li>
        <li class="desc">Хлопчатобумажная;</li>
        <li class="desc">Искусственная;</li>
        <li class="desc">Смешанные нити.</li>
      </ul>
      <p class="annotation">* На пряжу можно добровольно оформить сертификат.</p>
    </swiper-slide>
    <swiper-slide>
      <div class="img-container">
        <img src="../../public/topten/topten5.svg" alt="" class="img" />
      </div>
      <h3 class="subtitle">Аксессуары, расчески для волос</h3>
      <ul class="description">
        <p class="desc">которые можно продавать с отказным письмом:</p>
        <li class="desc">Заколки;</li>
        <li class="desc">Резинки;</li>
        <li class="desc">Ободки;</li>
        <li class="desc">Брашинг и др. изделия.</li>
      </ul>
      <p class="annotation">
        * На электрическую расческу или расческу для детей требуется
        декларация или сертификат.
      </p>
    </swiper-slide>
    <swiper-slide>
      <div class="img-container">
        <img src="../../public/topten/topten6.svg" alt="" class="img" />
      </div>
      <h3 class="subtitle">Печатная продукция</h3>
      <ul class="description">
        <p class="desc">
          Если печатная продукция не предназначена для детей, то
          процесс сертификации или декларирования можно пропустить. Не имеет
          значения книги (открытки, журналы или комиксы).
        </p>
      </ul>
    </swiper-slide>
    <swiper-slide>
      <div class="img-container">
        <img src="../../public/topten/topten7.svg" alt="" class="img" />
      </div>
      <h3 class="subtitle">Сувениры</h3>
      <ul class="description">
        <p class="desc">которые можно продавать с отказным письмом:</p>
        <li class="desc">Статуэтки;</li>
        <li class="desc">Копилки;</li>
        <li class="desc">Значки и пр. продукция.</li>
      </ul>
      <p class="annotation">
        * Сувенирная продукция, предназначенная для детей требует оформления декларации или сертификата.
      </p>
    </swiper-slide>
    <swiper-slide>
      <div class="img-container">
        <img src="../../public/topten/topten8.svg" alt="" class="img" />
      </div>
      <h3 class="subtitle">Товары для дома</h3>
      <ul class="description">
        <p class="desc">которые можно продавать с отказным письмом:</p>
        <li class="desc">Постельное белье;</li>
        <li class="desc">Кухонная утварь;</li>
        <li class="desc">Предметы интерьера;</li>
      </ul>
      <p class="annotation">
        * Бытовая химия не является товаром для дома.
      </p>
    </swiper-slide>
    <swiper-slide>
      <div class="img-container">
        <img src="../../public/topten/topten9.svg" alt="" class="img" />
      </div>
      <h3 class="subtitle">Игрушки и развлечения для взрослых</h3>
      <ul class="description">
        <p class="desc">В эту категорию входят товары 18+, работающие не на батарейках и не от зарядки. Например, настольные игры.</p>
      </ul>
    </swiper-slide>
  </swiper>
</template>
<script>
import TitleMain from "@/ui/TitleMain.vue";
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useSwiper } from "swiper/vue";

// Import Swiper styles
export default {
  components: {
    Swiper,
    SwiperSlide,
    TitleMain,
  },
  setup() {
    const swiper = useSwiper();
    return {
      swiper,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
};
</script>
<style lang="scss" scoped>
.swiper-button-prev,
.swiper-button-next::after {
  width: 10px;
}

.navigation {
  display: flex;
  justify-content: end;
  gap: 12px;
}

.title {
  margin-top: 140px;

  @media (max-width: 1024px) {
    margin-top: 40px;
  }
}

.slider {
  display: flex;
  margin-top: 40px;
}

.carousel__slide {
  justify-content: normal;

  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 24px;
  border-radius: 16px;
}

.subtitle {
  font-size: 32px;
  font-weight: 400;
  line-height: 35.2px;
  text-align: left;
}

.description {
  margin-top: 12px;

  li {
    list-style: inside;
  }
}

.desc {
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;

}

.annotation {
  font-size: 13px;
  font-style: italic;
  font-weight: 300;
  line-height: 19.5px;
  text-align: left;
  color: rgba(33, 39, 42, 0.5);
  margin-top: 12px;
}
</style>
